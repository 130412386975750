<template>
  <div>
    <v-select
      :items="customFieldValues"
      v-model="computedCol"
      disabled
    />
  </div>
</template>

<script>
export default {
  computed: {
    computedCol: {
      get () { return this.dataCol },
      set (v) {
        this.dataCol = v
        this.dataDocumentEmit()
      }
    }
  },
  methods: {
    emitDocument () {
      const doc = { col: this.dataCol }

      if (!this.lodash.isEqual(this.document, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    }
  },
  data () {
    return {
      customFieldValues: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29
      ],
      dataCol: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  props: {
    document: Object,
    targetDocTy: String
  },
  watch: {
    document: {
      immediate: true,
      handler (v) {
        this.dataCol = v?.col
      }
    }
  }
}
</script>
